import React, { useState } from "react";
import "./../css/main.css";

import { FaPhone, FaLinkedin, FaEnvelope, FaGithub } from "react-icons/fa";

function Contact() {

  return (
    <div className="flex-container">
      <div className="rectangle">
        <div className="container1-left">
          <div className="info-me">
            <h1>Rebekka Bratlie</h1>
            <div className="lineheight-list">
              <a href="mailto:Rebekkabratlie@gmail.com">
                <FaEnvelope /> Rebekkabratlie@gmail.com
              </a>
            </div>
            <div className="lineheight-list">
              <a href="https://github.com/BeckaNB">
                <FaGithub /> github.com/BeckaNB
              </a>
            </div>
            <div className="lineheight-list">
              <a href="https://www.linkedin.com/in/rebekka-bratlie">
                <FaLinkedin /> LinkedIn Profile
              </a>
            </div>
          </div>
        </div>

        <div className="container1-right">
         <img src={require("../images/pixel-becka.png")} className="medium_image_centered" alt="Pixel image of girl with brown hair in a ponytail and green eyes" />
           
        
        </div>
      </div>
    </div>
  );
}

export default Contact;
