import React, { useEffect, useState } from "react";

const TypeWriter = ({ text }: { text: string }) => {
  const [content, setContent] = useState(""); // Initialize with an empty string
  const [showCursor, setShowCursor] = useState(false); // Start with cursor hidden

  useEffect(() => {
    let index = 0;

    // Delay before starting the typing animation
    const startDelayTimer = setTimeout(() => {
      // Start typing animation after the delay
      const typingTimer = setInterval(() => {
        if (index < text.length) {
          setContent((c) => c + text[index]);
          index++;
        } else {
          clearInterval(typingTimer);
        }
      }, 200); // Typing speed

      return () => clearInterval(typingTimer);
    }, 3000); // Delay before typing starts (adjust as needed)

    // Show cursor after a certain delay
    const cursorDelayTimer = setTimeout(() => {
      setShowCursor(true);
    }, 2000); // Delay before showing the cursor (adjust as needed)

    // Toggle cursor blinking
    const cursorBlinkInterval = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500); // Cursor blinking speed (adjust as needed)

    return () => {
      clearTimeout(startDelayTimer);
      clearTimeout(cursorDelayTimer);
      clearInterval(cursorBlinkInterval);
    };
  }, [text]);

  return (
    <div className="typewriter">
      {content}
      {showCursor && <span style={{ opacity: showCursor ? 1 : 0 }}>|</span>}
    </div>
  );
};

export default TypeWriter;
