import React, { useState } from "react";
import PopUp from "./../components/PopupMessage/popup";
import "./../css/main.css";
import TypeWriter from "./../components/TypingEffect/Typing";

function Home() {
  const [isPopUpClosed, setIsPopUpClosed] = useState(false);

  const handlePopUpClose = () => {
    setIsPopUpClosed(true);
  };

  return (
    <div className="flex-container">
      <PopUp onClose={handlePopUpClose} /> {/* Pass onClose callback to PopUp */}
      {isPopUpClosed && (
        <div className="rectangle">
          <div className="container1-left">
            <div className="typing">
              <div className="box">
                <TypeWriter text="Welcome to BeckaWeb!" />
              </div>
            </div>
          </div>
          <div className="container1-right">
            <img
              src={require("../images/RebekkaBratlie03.jpg")}
              alt="Could not find"
              className="img_rounded-corners"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
