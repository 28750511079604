import React from "react";
import "./navbar.css";

import { Link } from "react-router-dom";

const Navbar = () => (
  <nav className="navbar">
    <div className="navbar-name">
      <h1 className="BeckaWeb">Rebekka Bratlie</h1>
    </div>
    <div className="navbarItemsContainer">
      <Link className="navbarItem" to={"/"}>
        Home
      </Link>
      <Link className="navbarItem" to={"/Contact"}>
        Contact
      </Link>
    </div>
  </nav>
);
export default Navbar;
