import React from "react";
import "./footer.css";
import { Button, Icon } from "semantic-ui-react";

const FooterComponent: React.FC = () => (
  <div className="footer">
    <div className="container-2">
      <p>Nettsiden har jeg utviklet selv med React.</p>
      <Button color="linkedin">
        <Icon name="linkedin" />
        LinkedIn
      </Button>
    </div>
    {/* <div className="container-3">
      <p>Rebekka Bratlie</p>
    </div> */}
  </div>
);

export default FooterComponent;

// FIKSE FARGE VARIABEL SLIK AT MAN IKKE TRENGER Å SKRIVE FARGEN OPP IGJEN
