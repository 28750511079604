import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/font.css";
import "./index.css";

import Home from "./pages/mainpage";
import Contact from "./pages/contact";
import Navbar from "./components/Navbar/navbar";
import FooterComponent from "./components/Footer/footer";

const App: React.FC = () => {
  return (
    <>
      <div className="App">
        <div className="background-img">
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Contact" element={<Contact />} />
            </Routes>
          </Router>
          <FooterComponent />
        </div>
      </div>
    </>
  );
};

export default App;
