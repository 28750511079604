import React, { Component } from "react";
import rightArrow from "./move-to-next.svg";
import "./popup.css";

interface PopUpProps {
  header?: string;
  desc?: string;
  acceptButtonText?: string;
  onClose?: () => void; // Callback function to be called on close
}

interface PopUpState {
  showPopup: boolean;
}

class PopUp extends Component<PopUpProps, PopUpState> {
  static defaultProps: PopUpProps = {
    header: "This website is under development",
    desc: "The website is not done and will be updated frequently",
    acceptButtonText: "Ok",
  };

  constructor(props: PopUpProps) {
    super(props);
    this.state = {
      showPopup: true,
    };
  }

  close = () => {
    this.togglePopup();
  };

  togglePopup(callback?: () => void) { // Accepts a callback function
    this.setState({
      showPopup: !this.state.showPopup,
    }, () => { // Execute callback after state is updated
      if (callback) {
        callback();
      }
    });
  }

  render() {
    if (!this.state.showPopup) return null;
    return (
      <div className="pop-up-outer">
        <div className="pop-up">
          <div className="pop-up-logo">
            {/**<img src={mainLogo} alt="" />**/}
          </div>
          <div className="main-field">
            <div className="header-field">{this.props.header}</div>
            <div className="desc-field">{this.props.desc}</div>
            <div className="main-button">
              <button className="accept-button" onClick={() => this.togglePopup(this.props.onClose)}>
                {this.props.acceptButtonText}
                <img className="accept-button-icon" src={rightArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopUp;
